import React, { useMemo } from 'react'
import { I18n, CDNSVG } from '@front/volcanion'

import { Box, Divider, Grid, Typography, Chip, Icon, Link, IconButton } from '@mui/material'

const CardItem = ({ value, icon, isLink, href, sx, children, ...props }) =>
  <Grid item xs={12} hidden={!value}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon color='primary'>{icon}</Icon>
      {!isLink &&
        <Typography sx={{ m: 'unset !important', pl: 1, fontSize: 13, ...sx }} {...props}>{value}</Typography>
      }
      {!!isLink &&
        <Link color='white' target='_blank' href={href}>
          <Typography color='primary' sx={{ m: 'unset !important', pl: 1, fontSize: 13, ...sx }} {...props}> {value}</Typography>
        </Link>
      }
      {children}
    </Box>
  </Grid >


const DriverTitle = ({ vehicle, ...props }) => {
  const status = _.get(vehicle, 'status')
  const chipColor = useMemo(() => {
    switch (status) {
      case 'available':
        return 'green'
      case 'unavailable':
        return 'red'
      case 'in_use':
        return 'orange'
      case 'en_route':
        return 'blue'
      default:
        return
    }
  }, [status])

  return (
    <Grid container item xs={12} rowSpacing={2}>
      <Grid item xs={12}><Divider /></Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='h5' sx={{ m: 'unset !important', fontSize: 'subtitle1.fontSize' }}> {I18n.t('sqr.driver.info')}</Typography>
          <Chip size='small' label={I18n.t(`sqr.vehicle.status.${status}`)} sx={{ ml: 2, fontWeight: 600, opacity: 0.8, bgcolor: chipColor, color: 'white' }} />
        </Box>
      </Grid>
      <Grid item xs={12}><Divider /></Grid>
    </Grid>
  )
}

const VehicleTitle = props =>
  <Grid container item xs={12} rowSpacing={2}>
    <Grid item xs={12}><Divider /></Grid>
    <Grid item xs={12}>
      <Typography variant='h5' sx={{ m: 'unset !important', textAlign: 'center', fontSize: 'subtitle1.fontSize' }}> {I18n.t('sqr.vehicle.info')}</Typography>
    </Grid>
    <Grid item xs={12}><Divider /></Grid>
  </Grid>

const VehicleInfo = ({ vehicle, ...props }) =>
  <Grid item xs={12}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon color='primary'>{'local_taxi'}</Icon>
      <Typography sx={{ m: 'unset !important', pl: 1, fontSize: 13 }}>
        {_.compact([_.get(vehicle, 'info.model.brand.name'), _.get(vehicle, 'info.model.name'), _.toLower(_.get(vehicle, 'info.color'))]).join(' ')} -
      </Typography>
      <Link color='white' target='_blank' href={`/adherent/${_.get(vehicle, 'group')}/vehicle/${_.get(vehicle, 'vehicle_id')}`}>
        <Typography color='primary' sx={{ m: 'unset !important' }}> {_.get(vehicle, 'info.plaque')}</Typography>
      </Link>
    </Box>
  </Grid >


const VehicleCard = ({ vehicle, onCall, props }) =>
  <Grid container rowSpacing={2}>
    <DriverTitle vehicle={vehicle} />
    <CardItem
      icon='bookmark_border'
      value={_.get(vehicle, 'current_job.first_step.order.order_client_id')}
      href={`/order/${_.get(vehicle, 'current_job.first_step.order.order_id')}`}
      isLink
    />
    <CardItem
      icon='person'
      value={`${_.get(vehicle, 'driver.info.first_name')} ${_.get(vehicle, 'driver.info.last_name')} - ${_.get(vehicle, 'driver.info.user_client_id')}`}
      href={`/driver/${_.get(vehicle, 'driver.user_id')}`}
      isLink
    />
    <CardItem
      icon={'phone'}
      value={_.get(vehicle, 'driver.auth.gsm')}
    >
      <IconButton onClick={() => onCall(_.get(vehicle, 'driver.auth.gsm'))} >
        <Box sx={{ color: 'primary.main', fontSize: 33 }}><CDNSVG name='customer-call' /></Box>
      </IconButton>
    </CardItem>
    <CardItem icon={'tune'} value={_.map(_.get(vehicle, 'driver.options'), 'name_translated').join(', ')} />
    <VehicleTitle />
    <VehicleInfo vehicle={vehicle} />
    <CardItem icon={'tune'} value={_.map(_.get(vehicle, 'options'), 'name_translated').join(', ')} />
  </Grid >


export default React.memo(VehicleCard)
